import * as React from "react";
import { Career, Layout } from "../components";
import Seo from "../components/Seo";

const CareerPage = () => {
  return (
    <Layout>
      <Seo
        title="採用情報"
        description="東京横丁は、一緒に働くメンバーを募集しています。"
        keywords="スキマ時間をもっと楽しく,東京横丁,とうきょうよこちょう,採用情報,キャリア,CAREER,リクルート,RECRUIT"
      />
      <Career />
    </Layout>
  );
};

export default CareerPage;
